import { template as template_6bd2e23a63354c09b194f27859b3e469 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6bd2e23a63354c09b194f27859b3e469(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
